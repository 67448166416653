import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({
  resources: {
    sr_RS_Latn: {
      translations: {
        Yes: 'Da',
        No: 'Ne',
        Save: 'Sačuvaj',
        Accept: 'Prihvati',
        Confirm: 'Potvrdi',
        Delete: 'Obriši',
        Cancel: 'Otkaži',
        AddInput: 'Unesi',
        Choose: 'Izaberi',
        WithoutPrivileges: 'Korisnik nema prava pristupa ovoj stranici!',
        NumberOfRows: 'Broj redova',
        OrderNumber: 'Rb',
        Codebooks: 'Šifarnici',
        ItemClassification: 'Klasifikacija artikala',
        NavigationMenu: 'Navigacioni meni',
        Search: 'Pretraga',
        Close: 'Zatvori',
        AllRows: 'Svi',
        NoResult: 'Nema rezultata',
        number: 'broj',
        CancelEditMode: 'Poništi režim izmene',
        CancelAddMode: 'Poništi režim dodavanja',
        HomePage: 'Početna strana',
        ToggleSidebar: 'Prikaži/sakrij bočnu traku',
        Login: 'Prijavi se',
        ForgotPassword: 'Zaboravljena sifra?',
        from: 'od',
        to: 'do',

        Users: 'Korisnici',
        UserName: 'Korisničko ime',
        CreateNewUser: 'Novi korisnik',
        Name: 'Naziv',
        FirstName: 'Ime',
        LastName: 'Prezime',
        Email: 'Email adresa',
        Role: 'Uloga',
        Roles: 'Uloge',
        RoleSelected: 'Dodeljena je {{count}} uloga',
        RoleSelected_few: 'Dodeljene su {{count}} uloge',
        RoleSelected_plural: 'Dodeljeno je {{count}} uloga',
        Password: 'Lozinka',
        RepeatPassword: 'Ponovite lozinku',
        AddUser: 'Dodavanje korisnika',
        EditUser: 'Izmena podataka korisnika',

        //INVENTORY
        Inventory: 'Popis',
        Inventories: 'Popisi',
        InventoryPreview: 'Pregled popisa',
        ConcludeInventory: 'Zaključi popis',
        UnlockInventory: 'Otključaj popis',
        ShowNonConcludedList: 'Prikaži nezaključene popisne liste',
        NonConcludedList: 'Nezaključene popisne liste',
        AddInventory: 'Dodaj popis',
        StartInventoryDate: 'Datum početka popisa',
        InventoryDate: 'Datum popisa',
        EndInventoryDate: 'Datum završetka popisa',
        GroupBy: 'Grupisanje po',
        GroupedBy: 'Grupisan po',
        GroupWAREHOUSE: 'Magacin',
        GroupACCOUNTABLE: 'Računopolagač',
        GroupACCOUNT: 'Konto',
        GroupLOCATION: 'Lokacija',
        GroupORUN: 'Organizaciona jedinica',
        GroupSelected: 'Dodeljeno grupa: {{count}}',
        StatusesSelected: 'Dodeljeno statusa: {{count}}',
        WarehouseInternalPurpose: 'Svrha magacina',
        WarehouseInternalPurposeSI_SUPPLY: 'Zalihe',
        WarehouseInternalPurposeSI_IN_USE: 'U upotrebi',
        WarehouseInternalPurposeSI_ON_REVERSE: 'Na reversu',
        CategorySI_SUPPLY: 'Zalihe',
        CategorySI_USAGE: 'U upotrebi',
        CategorySI_REVERSE: 'Na reversu',
        LockList: 'Zaključaj listu',
        UnlockList: 'Otključaj listu',
        ConcludeList: 'Zaključi listu',
        EqualizeList: 'Izjednačiti listu',
        DeleteInventory: 'Brisanje popisa',
        DeleteInventoryDesc: 'Da li ste sigurni da želite da obrišete popis?',
        RegenerateInventory: 'Regenerisanje popisa',
        RegenerateInventoryDesc:
          'Da li ste sigurni da želite da regenerišete popis?\nZaključene i zaključane liste neće biti predmet regenerisanja.\nUneseni podaci na otključanim popisnim listama neće biti sačuvani.',
        InventorySearch: 'Pretraga popisa',
        ShowInvalidListItems: 'Prikaži nevalidne stavke',
        InvalidListItems: 'Nevalidne stavke sa popisnih listi',
        DocumentSurpluses: 'Dokumenta viška',
        DocumentMinuses: 'Dokumenta manjka',
        DocumentExpenses: 'Dokumenta rashoda',
        GenerateDocumentProposals: 'Generiši predloge dokumenata',
        ProcessedList: 'Obrađena lista',

        SI_SUPPLYExpenseQuantity: 'Rashod sa zaliha',
        SI_USAGEExpenseQuantity: 'Rashod na upotrebi',
        SI_SUPPLYMinusQuantity: 'Manjak sa zaliha',
        SI_USAGEMinusQuantity: 'Manjak sa upotrebe',
        SI_SUPPLYSurplusQuantity: 'Višak na zalihama',
        SI_USAGESurplusQuantity: 'Višak na upotrebi',

        InventoryType: 'Tip popisa',
        TypeREGULAR: 'Redovni',
        TypeNON_REGULAR: 'Vanredni',
        TypeTRIAL: 'Probni',
        InventoryListItemsSearch: 'Pretraga stavki popisne liste',
        InventoryListsSearch: 'Pretraga popisnih listi',

        InventoryLists: 'Popisne liste',
        InventoryList: 'Popisna lista',
        InventoryListPreview: 'Pregled popisne liste',
        BookStatus: 'Status stanja knjige',
        SaveInventoryList: 'Sačuvaj popisnu listu',
        PrintInventory: 'Štampa popisa',

        InventoryListStatusCONCLUDED: 'Zaključena',
        InventoryListStatusLOCKED: 'Zaključana',
        InventoryListStatusUNLOCKED: 'U izradi',
        InventoryListStatusDRAFT: 'U izradi',

        StatusACTIVE: 'Aktivan',
        StatusPASSIVE: 'Pasivan',

        Filter: 'Filter',
        Organizations: 'Organizacije',
        RegistrationNumber: 'Matični broj',
        TaxId: 'PIB',
        AddOrganization: 'Dodavanje organizacije',
        EditOrganization: 'Izmena podataka organizacije',
        ViewOrganization: 'Pregled organizacije',
        OrganizationsSearch: 'Pretraga organizacija',

        UnitOfMeasure: 'Jedinica mere',
        UnitsOfMeasure: 'Jedinice mere',
        AddUnitOfMeasure: 'Dodavanje jedinice mere',
        EditUnitOfMeasure: 'Izmena podataka jedinice',

        ClassificationType: 'Tip klasifikacije',
        ClassificationTypes: 'Tipovi klasifikacija',
        Description: 'Opis',
        Primary: 'Primaran',
        AddClassificationType: 'Dodavanje tipa klasifikacije',
        EditClassificationType: 'Izmena podataka tipa klasifikacije',

        Classification: 'Klasifikacija',
        Mandatory: 'Obavezno',
        AddClassification: 'Dodavanje klasifikacije',
        AddToClassification: 'Dodavanje klasifikaciji',
        EditClassification: 'Izmena podataka klasifikacije',
        UserDefinedCode: 'Korisnički definisan kod',
        AddAttribute: 'Dodaj atribut',

        MainProducts: 'Primarni artikli',
        MainProduct: 'Primaran artikal',
        MainProductList: 'Lista primarnih artikala',
        MinStockLevel: 'Minimalno zaliha',
        ClassificationName: 'Ime klasifikacije',
        FilterByName: 'Filtriraj po imenu',
        FilterByCode: 'Filtriraj po kodu',
        FilterByInventoryNumber: 'Filtriraj po inventarskom broju',
        AddMainProduct: 'Dodavanje primarnog artikla',
        ViewMainProduct: 'Pregled primarnog artikla',
        EditMainProduct: 'Izmena podataka primarnog artikla',
        AddProductClassification: 'Dodaj klasifikaciju',
        MainProductClassifications: 'Klasifikacije primarnog artikla',

        Products: 'Artikli',
        ProductName: 'Naziv artikla',
        Code: 'Šifra',
        OldCode: 'Stara šifra',
        AddProduct: 'Dodavanje artikla',
        EditProduct: 'Izmena podataka artikla',
        AveragePrice: 'Prosečna cena',

        FrameworkAgreement: 'Okvirni sporazum',
        ProcurementApply: 'Nabavke na koje se Zakon primenjuje',

        Wms: 'Magacinsko poslovanje',
        Warehouse: 'Magacin',
        SelectAllWarehouses: 'Označi sve magacine',
        Warehouses: 'Magacini',
        Purpose: 'Svrha',
        Type: 'Tip',
        Edit: 'Izmeni',
        AddWarehouse: 'Dodaj magacin',
        AddWarehouseTitle: 'Dodavanje magacina',
        WarehouseConfiguration: 'Konfiguracija magacina',
        EditWarehouse: 'Izmena podataka magacina',

        Address: 'Adresa',
        StorageUnit: 'Skladišna jedinica',
        EditStorageUnit: 'Izmeni jedinicu skladištenja',
        AddStorageUnit: 'Dodaj jedinicu skladištenja',

        OrganizationalUnit: 'Organizaciona jedinica',
        OrganizationalUnits: 'Organizacione jedinice',
        AddOrganizationalUnit: 'Dodavanje organizacione jedinice',
        AddToOrganizationalUnit: 'Dodavanje organizacionoj jedinici',
        EditOrganizationalUnit: 'Izmena podataka organizacione jedinice',

        Locale: 'Jezik',
        Administration: 'Administracija',

        AddOrun: 'Dodaj organizacionu jedinicu',

        Assets: 'Osnovna sredstva',
        Asset: 'Osnovno sredstvo',
        InventoryNumber: 'Inventarski broj',
        ExternalInventoryNumberShort: 'Stari inv. br.',
        Date: 'Datum',
        ChooseDate: 'Izaberi datum',
        EditAsset: 'Izmena podataka osnovnog sredstva',
        EditAssetTitle: 'Kartica osnovnog sredstva',
        AddAsset: 'Dodaj osnovno sredstvo',
        AddAssetTitle: 'Dodavanje osnovnog sredstva',
        Barcode: 'Bar-kod',
        Barcodes: 'Bar-kodovi',
        AddBarcode: 'Dodaj bar-kod',
        Value: 'Vrednost',
        BarcodeType: 'Tip bar-koda',
        PurchaseValue: 'Nabavna vrednost',
        PurchaseValueWithoutVat: 'Nabavna vrednost bez PDV-a',
        PurchaseValueWithVat: 'Nabavna vrednost sa PDV-om',
        VatPercentage: 'PDV %',
        VatAmount: 'Iznos pdva',
        LifespanYear: 'Životni vek - godina',
        DeleteBarcodeConfirmation: 'Da li ste sigurni da želite da izbrišete bar-kod?',
        DeleteBarcodeTitle: 'Brisanje bar-koda',
        FixedAssetEvidence: 'Evidencija',
        FixedAssetList: 'Lista osnovnih sredstava',
        TotalValue: 'Ukupna vrednost',
        LegalEntities: 'Pravna lica',
        LegalEntity: 'Pravno lice',
        LegalForm: 'Pravna forma',
        AddressBook: 'Adresar',
        PartnerListView: 'Prikaz liste partnera',
        AddLegalEntity: 'Dodavanje partnera',
        AddLegalEntityFromNBS: 'Dodavanje partnera sa NBS',
        AddLegalEntityManually: 'Ručno dodavanje partnera',
        LegalEntityEdit: 'Izmena partnera',
        LegalEntityAddress: 'Adresa sedišta',
        LegalEntityHouseNumber: 'Broj adrese sedišta',
        LegalEntityTown: 'Grad sedišta',
        LegalEntitySearch: 'Pretraga partnera',
        NameFilter: 'Pretraga po nazivu',
        TaxIdFilter: 'Pretraga po PIB-u',
        RegistrationNumberFilter: 'Pretraga po matičnom broju',
        BusinessActivity: 'Šifra delatnosti',
        PersonalIdentificationNumber: 'JMBG',
        IsResponsiblePerson: 'Odgovorno lice?',
        IsDirector: 'Direktor?',
        AddContactPerson: 'Dodavanje kontakt osobe',
        EditContactPerson: 'Izmena kontakt osobe',
        PostalCode: 'Poštanski broj',
        Website: 'Veb sajt',
        Phone: 'Telefon',
        Group: 'Grupa',
        Groups: 'Grupe',
        AddGroup: 'Dodavanje grupe',
        EditGroup: 'Izmena grupe',
        ViewGroup: 'Pregled grupe',
        GroupType: 'Tip grupe',
        RequiredFieldsError: 'Polja označena * su obavezna',
        FetchFromNbs: 'Preuzmi podatke sa NBS',
        ContactInformation: 'Kontakt podaci',
        ContractEdit: 'Izmena ugovora',
        BankAccounts: 'Tekući računi',
        Bank: 'Banka',
        BankAccount: 'Tekući račun',
        EditBankAccount: 'Izmena tekućeg računa',
        AddBankAccount: 'Dodavanje tekućeg računa',
        MainBankAccount: 'Primarni račun',
        Notes: 'Napomene',
        AddNote: 'Dodavanje napomene',
        EditNote: 'Izmena napomene',
        CreatedBy: 'Sastavio',
        Subject: 'Predmet',
        ShowLocationOnMap: 'Prikazati lokaciju na mapi',
        AddressNotFound: 'Adresa nije pronađena',
        Documentation: 'Dokumentacija',
        LegalEntitiesGroups: 'Grupe pravnih lica',
        NBSSearch: 'Pretraga partnera na NBS',
        RelatedDocument: 'Povezan dokument',
        CopyItemsFromDocument: 'Kopiraj stavke sa dokumenta',

        CostCenter: 'Mesto troška',
        AddCostCenter: 'Dodavanje mesta troška',
        AddToCostCenter: 'Dodavanje mestu troška',
        EditCostCenter: 'Izmena mesta troška',
        AmountCorrection: 'Ispravka vrednosti',

        DeleteWarehouse: 'Obriši magacin',
        DeleteWarehouseDesc: 'Da li ste sigurni da želite da obrišete magacin',

        Active: 'Aktivan',
        Inactive: 'Neaktivan',
        Passive: 'Pasivan',

        InvoiceNumber: 'Broj fakture',
        InvoiceDate: 'Datum fakture',
        InvoiceDateFrom: 'Datum fakture od',
        InvoiceDateTo: 'Datum fakture do',

        Document: 'Dokument',
        Documents: 'Dokumenti',
        DocumentNumber: 'Broj dokumenta',
        DocumentType: 'Tip dokumenta',
        InboundDocuments: 'Ulazna dokumenta',
        OutboundDocuments: 'Izlazna dokumenta',
        ContainAtLeastOneDocumentType: 'Sadrži bar jedan tip dokumenta',
        DocumentDateFrom: 'Datum dokumenta od',
        DocumentDateTo: 'Datum dokumenta do',
        ActivationDocumentType: 'Aktivacioni tip dokumenta',

        DocumentTypes: 'Tipovi dokumenta',
        AddDocumentType: 'Dodaj tip dokumenta',
        EditDocumentType: 'Izmena tipa dokumenta',
        Category: 'Kategorija',
        CategoryINBOUND: 'Ulazni',
        CategoryOUTBOUND: 'Izlazni',
        DocumentTypeInternalCode: 'Vrsta tipa dokumenta',
        Required: 'Obavezno',
        InternalCodeRECEIPT_FROM_SUPPLIER: 'Prijem od dobavljača',
        InternalCodeRETURN_FROM_CUSTOMER: 'Povrat od kupca',
        InternalCodeRECEIPT_FROM_PRODUCTION: 'Prijem iz proizvodnje',
        InternalCodeRETURN_TO_SUPPLIER: 'Povrat dobavljaču',
        InternalCodeSHIPPING_LIST: 'Otpremnica',
        InternalCodeINTERNAL_TRANSFER: 'Interni prenos',
        InternalCodeSURPLUS: 'Višak',
        InternalCodeMINUS: 'Manjak',

        DocumentFields: 'Polja na dokumentu',
        ItemsFields: 'Polja na stavkama',
        SUPPLIER: 'Dobavljač',
        INVOICE_NUMBER: 'Broj fakture',
        REFERENCE_DOCUMENT_DUE_DATE: 'Datum valute',
        REFERENCE_DOCUMENT_DATE: 'Datum dokumenta',
        NOTE: 'Napomena',
        WAREHOUSE_MAN: 'Magacioner',
        EMPLOYEE: 'Zaposleni',
        LOCATION: 'Lokacija',
        RELATED_DOCUMENT: 'Povezan dokument',
        DOCUMENT_NUMBER_BASIS: 'Broj dokumenta - osnov',
        PRICE: 'Cena',
        PURCHASE_VALUE_WITH_VAT: 'Nabavna vrednost sa PDV-om',
        PURCHASE_VALUE_WITHOUT_VAT: 'Nabavna vrednost bez PDV-a',
        VALUE_CORRECTION: 'Ispravka vrednosti',
        CURRENT_VALUE: 'Sadašnja vrednost',
        VAT_PERCENTAGE: 'PDV %',
        VAT_AMOUNT: 'Iznos PDV-a',
        VALUE_WITH_VAT: 'Vrednost sa PDV-om',

        FixedAsset: 'Osnovno sredstvo',
        FixedAssets: 'Osnovna sredstva',
        Add: 'Dodaj',
        Supplier: 'Dobavljač',
        SupplierName: 'Naziv dobavljača',
        AddDocumentTitle: 'Dodavanje dokumenta',
        AddDocument: 'Dodaj dokument',
        EditDocument: 'Izmeni dokument',
        PreviewDocument: 'Pregled dokumenta',
        DocumentItems: 'Stavke dokumenta',
        AddDocumentItem: 'Dodaj stavku dokumenta',
        EditDocumentItem: 'Izmeni stavku dokumenta',
        DeleteDocumentItemConfirmation:
          'Da li ste sigurni da želite da izbrišete stavku dokumenta?',
        DeleteDocumentConfirmation: 'Da li ste sigurni da želite da izbrišete dokument?',
        DeleteDocumentItemTitle: 'Brisanje stavke dokumenta',
        AmortizationGroup: 'Amortizaciona grupa',
        AmortizationSubGroup: 'Amortizaciona pod grupa',
        AmortizationGroupThirdLevel: 'Alineja',
        AmortizationGroups: 'Amortizacione grupe',
        AmortizationTaxGroups: 'Poreske grupe',
        AmortizationTaxSubGroup: 'Poreska pod grupa',
        ParentGroup: 'Roditeljska grupa',
        AmortizationTaxGroup: 'Poreska grupa',
        EditAmortizationGroup: 'Izmena amortizacione grupe',
        AddAmortizationGroup: 'Dodavanje amortizacione grupe',
        AddAmortizationTaxGroup: 'Dodavanje poreske grupe',
        EditAmortizationTaxGroup: 'Izmena poreske grupe',
        AmortizationData: 'Obračunski podaci',
        AmortizationRate: 'Stopa amortizacije',
        DocumentItemDetails: 'Podaci o stavci dokumenta',
        FixedAssetDetails: 'Podaci o osnovnom sredstvu',
        ActivationDate: 'Datum aktivacije',
        ActivationDateFrom: 'Datum aktivacije od',
        ActivationDateTo: 'Datum aktivacije do',
        StockHistory: 'Robna kartica',
        FinancialCard: 'Finansijska kartica',
        DepreciatedValue: 'Otpisana vrednost',
        TotalDepreciatedValue: 'Otpisana vr. pre rashoda',
        CurrentValue: 'Sadašnja vrednost',
        DocumentNumberBase: 'Broj dokumenta - osnov',

        Employees: 'Zaposleni',
        Employee: 'Zaposlen',
        EvidenceNumber: 'Evidencioni broj',
        PersonalIdentityNumber: 'JMBG',
        GivenName: 'Ime',
        FamilyName: 'Prezime',
        AddEmployee: 'Dodavanje zaposlenog',
        EditEmployee: 'Izmena podataka zaposlenog',
        ViewEmployee: 'Pregled zaposlenog',

        Locations: 'Lokacije',
        Representation: 'Reprezentacija',
        ViewLocation: 'Pregled lokacije',
        AddLocation: 'Dodavanje lokacije',
        EditLocation: 'Izmena podataka lokacije',

        BasicInfo: 'Opšti podaci',
        CommercialInfo: 'Komercijalni podaci',
        LocationChange: 'Kretanje sredstava',
        Location: 'Lokacija',
        LatestLocation: 'Trenutna lokacija',
        NewLocation: 'Nova lokacija',

        Accountable: 'Računopolagač',
        LatestAccountable: 'Trenutni računopolagač',

        StorageUnitsByArea: 'Jedinice skladištenja unutar zone',
        AreaType: 'Tip zone skladištenja',
        AddArea: 'Dodaj zonu skladištenja',
        WarehouseArea: 'Zona u magacinu',
        WarehouseAreas: 'Zone u magacinu',
        OrunsWithAllowedStorageAccess:
          'Organizacione jedinice sa dozvoljenim pristupom magacinu',

        AssetList: 'Popis',
        AssetLists: 'Popisne liste',
        AddAssetList: 'Dodavanje popisne liste',
        PreviewAssetList: 'Pregled popisne liste',
        QuantityByBooks: 'Stanje po knjigama-količina',
        PurchaseValueByBooks: 'Nabavna vrednost po knjigama',
        ValueAdjustmentByBooks: 'Ispravka vrednosti po knjigama',
        CurrentValueByBooks: 'Sadašnja vrednost po knjigama',
        UsageExpense: 'Rashod na upotrebi',
        UsageDeficit: 'Manjak sa upotrebe',
        UsageSurplus: 'Višak na upotrebi',
        InitialValueAdjustment: 'Početna ispravka vrednosti',

        StockExpense: 'Rashod sa zaliha',
        StockDeficit: 'Manjak sa zaliha',
        StockSurplus: 'Višak na zalihama',
        SiSupplyInventoryList: 'Popis sitnog inventara (zalihe) - obrađena lista',
        SiInUseInventoryList: 'Popis sitnog inventara (upotreba) - obrađena lista',
        InventoryListOnDay: 'Popisna lista na dan',
        InventoryOnDay: 'Popis na dan',

        BookQuantity: 'Stanje po knjigama',
        RealQuantity: 'Stanje popisa',
        AssetListItems: 'Stavke popisne liste',
        AssetListRecapitulationOnDate: 'Popisna lista osnovnih sredstava na dan',
        AssetListSmallInventoryOnDate: 'Popisna lista sitnog inventara na dan',
        ProcessedAssetListRecapitulation: 'Popis osnovnih sredstava - obrađena lista',
        ProcessedAssetListSmallInventory: 'Popis sitnog inventara - obrađena lista',

        FromAccountant: 'Od računopolagača',
        FromAccountable: 'Sa računopolagača',
        ToAccountable: 'Na računopolagača',

        Change: 'Promeni',
        ChangeOrganization: 'Promeni organizaciju',
        LogOut: 'Odjavi se',
        OrganizationChange: 'Promena organizacije',
        LoggedUserOrganizations: 'Lista organizacija ulogovanog korisnika',

        GoToHomepage: 'Idi na početnu stranu',
        WrongOrganizationMessage: 'Traženi resurs ne pripada organizaciji',

        Account: 'Konto',
        Accounts: 'Konta',
        AddAccount: 'Dodaj konto',
        EditAccount: 'Izmeni konto',
        ViewAccount: 'Pregledaj konto',

        PurchaseDateFrom: 'Datum nabavke od',
        PurchaseDateTo: 'Datum nabavke do',
        ShowSearch: 'Prikaži pretragu',
        ExtendSearch: 'Proširi pretragu',
        ReduceSearch: 'Suzi pretragu',
        HideSearch: 'Sakrij pretragu',
        AccountingReady: 'Spremno za knjiženje',
        ConcludeDocument: 'Zaključi dokument',
        UnlockDocument: 'Otključaj dokument',
        ConcludeDocumentConfirmation: `Da li ste sigurni da želite da zaključite dokument? \n Nakon toga izmene neće biti moguće.`,
        ConcludeDocumentConfirmationWithAvgPriceChange: `Da li ste sigurni da želite da zaključite dokument? \n Nakon toga izmene neće biti moguće. \n\n Zaključavanjem dokumenta izmeniće se prosečna cena na dokumentima `,
        UnlockDocumentConfirmation: 'Da li ste sigurni da želite da otključate dokument?',
        Concluded: 'Zaključen',
        NotConcluded: 'U izradi',
        AssetDocumentNote:
          'Dokument nije zaključen (spreman za kniženje). Promene neće imati uticaja na kartice dokle god dokument nije zaključen',
        AssetDocumentNote2:
          'Ukoliko broj dokumenta ostane prazan program će zauzeti prvi slobodan broj',

        ERP_WMS: 'Magacinsko poslovanje',
        ADMINISTRATION: 'Administracija',
        ASSETS: 'Osnovna sredstva',
        CONTRACTS: 'Ugovori i okvirni sporazumi',
        ERP_SMALL_INVENTORY: 'Sitan inventar',
        PROCUREMENT: 'Javne nabavke',
        ExternalInventoryNumber: 'Stari inventarski broj',
        SourceOfSupply: 'Izvor nabavke',
        Quantity: 'Količina',
        Stock: 'Zaliha',
        OnStockQuantity: 'Na stanju',
        Total: 'Ukupno',
        PrintProductCard: 'Prikaži karticu',
        InventoryNumberShort: 'Inv. br.',
        FixedAssetRecapitulation: 'Rekapitulacija osnovnih sredstava',
        SmallInventorycapitulation: 'Rekapitulacija sitnog inventara',
        FixedAssetRecapitulationByLOCATION:
          'Rekapitulacija osnovnih sredstava po lokaciji',
        SmallInventoryRecapitulationByLOCATION:
          'Rekapitulacija sitnog inventara po lokaciji',
        FixedAssetRecapitulationByACCOUNT: 'Rekapitulacija osnovnih sredstava po kontu',
        SmallInventoryRecapitulationByACCOUNT: 'Rekapitulacija sitnog inventara po kontu',
        FixedAssetRecapitulationByACCOUNTABLE:
          'Rekapitulacija osnovnih sredstava po računopolagaču',
        SmallInventoryRecapitulationByACCOUNTABLE:
          'Rekapitulacija sitnog inventara po računopolagaču',
        FixedAssetRecapitulationByMUNICIPALITY:
          'Rekapitulacija osnovnih sredstava po opštini',
        SmallInventoryRecapitulationByMUNICIPALITY:
          'Rekapitulacija sitnog inventara po opštini',
        FixedAssetRecapitulationByTYPE_OF_CONSTRUCTION:
          'Rekapitulacija osnovnih sredstava po vrsti gradnje',
        SmallInventoryRecapitulationByTYPE_OF_CONSTRUCTION:
          'Rekapitulacija sitnog inventara po vrsti gradnje',
        FixedAssetRecapitulationBySUPPLIER:
          'Rekapitulacija osnovnih sredstava po dobavljaču',

        WithoutValue: 'Bez vrednosti',
        WithoutAttributeValue: 'Bez unetog atributa',
        Reports: 'Izveštaji',
        DeprecationCalculation: 'Obračun amortizacije',
        DeprecationCalculationEdit: 'Izmena obračuna amortizacije',
        AddDeprecationCalculation: 'Dodavanje obračuna amortizacije',
        Note: 'Napomena',
        FromDate: 'Od datuma',
        ToDate: 'Do datuma',
        LastRunDate: 'Datum poslednjeg pokretanja',
        CreateNewCalculation: 'Napravi novi obračun',
        Deprecation: 'Amortizacija',
        CalculationDate: 'Datum obračuna',
        CalculationPeriod: 'Obračunski period',
        Successfully: 'Uspešno',
        DeletedSuccessfully: 'Uspešno obrisano',
        AssetsInCalculation: 'Sredstva u obračunu',
        AddAssetInCalculation: 'Dodaj sredstvo u obračun',
        AddAssetsInBulkInCalculation: 'Grupno dodavanje sredstava u obračun',
        ProcessingInProgress: 'Procesuiranje u toku',
        AddActiveAssetsInBulk: 'Dodaj sva aktivna sredstva',
        AmortizationDocuments: 'Nalozi amortizacije',
        AddAssetsInBulk: 'Dodaj sredstva grupno',
        Actions: 'Akcije',
        RunCalculation: 'Pokreni obračun',
        LockCalculation: 'Zaključaj obračun',
        AssetTypeFIXED_ASSET: 'Osnovna sredstva',
        AssetTypeSMALL_INVENTORY: 'Sitan inventar',
        AssetTypeShortFIXED_ASSET: 'OS',
        AssetTypeShortSMALL_INVENTORY: 'SI',
        Print: 'Odštampaj',
        SaveXLSX: 'Sačuvaj XLSX',
        PrintList: 'Odštampajte listu',
        PrintProcessedList: 'Štampa obrađene liste',
        PrintNonProcessedList: 'Štampa neobrađene liste',
        ConfigureTheReport: 'Konfigurišite izveštaj',
        ElementOfGrouping: 'Element grupisanja',
        MUNICIPALITY: 'Opština',
        FormatBy: 'Formiraj po',
        SiSupply: 'SI zalihe',
        SiInUse: 'SI upotreba',
        SortBy: 'Sortiraj po',
        FilterBy: 'Filtriraj po',
        FilterByProduct: 'Filtriraj po artiklu',
        FilterByOrganizationalUnit: 'Filtriraj po organizacionoj jedinici',
        FilterByLocation: 'Filtriraj po lokaciji',
        FilterByAccountable: 'Filtriraj po računopolagaču',
        FilterByAccount: 'Filtriraj po kontu',
        FilterBySupplier: 'Filtriraj po dobavljaču',
        FilterByWarehouse: 'Filtriraj po magacinu',
        FilterByBookStatus: 'Filtriraj po statusu knjige',
        PageBreak: 'Prelom stranice',
        WithoutGrouping: 'Bez grupisanja',
        GroupByLocation: 'Grupiši po lokaciji',
        GroupByAccountable: 'Grupiši po računopolagaču',
        GroupByAccount: 'Grupiši po kontu',
        GroupBySupplier: 'Grupiši po dobavljaču',
        ReportGroupByLOCATION: 'Izveštaj sitnog inventara po lokaciji',
        ReportGroupByACCOUNTABLE: 'Izveštaj sitnog inventara po računopolagaču',
        ReportGroupByACCOUNT: 'Izveštaj sitnog inventara po kontu',
        ReportGroupBySUPPLIER: 'Izveštaj sitnog inventara po dobavljaču',
        ReportGroupByWAREHOUSE: 'Izveštaj sitnog inventara po magacinima',
        ByAccount: 'Kontu',
        ByAssetName: 'Nazivu osnovnog sredstva',
        ByInventoryNumber: 'Inventarskom broju',
        WithoutPageBreak: 'Bez preloma stranice',
        ByGroupItem: 'Po elementu grupisanja',
        UnitOfMeasureShort: 'JM.',
        CountShort: 'Kol.',
        ControlNumber: 'Lot',
        ResponsiblePerson: 'Odgovorno lice',
        CommissionMembers: 'Članovi komisije',
        CancelSearch: 'Poništi pretragu',
        SearchByInventoryNumber: 'Pretraga po inv. br.',
        SearchByExternalInventoryNumber: 'Pretraga po starom inv. br.',
        SearchByName: 'Pretraga po nazivu',
        PurchaseDate: 'Datum nabavke',
        Rate: 'Stopa',
        FixedAssetName: 'Naziv sredstva',
        CalculationAssetsSearch: 'Pretraga sredstava u obračunu',
        OrunShort: 'OJ',
        DeprecationCalculationRecapitulationByLOCATION:
          'Rekapitulacija obračuna po lokaciji',
        DeprecationCalculationRecapitulationByACCOUNTABLE:
          'Rekapitulacija obračuna po računopolagaču',
        DeprecationCalculationRecapitulationByACCOUNT: 'Rekapitulacija obračuna po kontu',
        DeprecationCalculationRecapitulationByNO_GROUPING: 'Rekapitulacija obračuna',
        Open: 'Otvori',
        SynteticRecapitulation: 'Sintetička rekapitulacija',
        SyntheticReports: 'Sintetički izveštaji',
        SupplierDocumentNumber: 'Broj dokumenta dobavljača',
        SupplierDocumentDate: 'Datum dokumenta dobavljača',
        SURPLUSBaseDocument: 'Osnov za unos viškova',
        DEFICITBaseDocument: 'Osnov za unos manjka',
        VALUE_REDUCTIONBaseDocument: 'Osnov za smanjenje',
        VALUE_INCREASEBaseDocument: 'Osnov za povećanje',
        SALEBaseDocument: 'Osnov za otuđenje',
        RECEIPTBaseDocument: 'fakture',
        EXPENSEBaseDocument: 'Osnov za rashod',
        PaymentDueDate: 'Valuta plaćanja',
        ReceivedInvoiceBookNumber: 'Broj KPR',
        Currency: 'Valuta',
        ExchangeRate: 'Kurs',
        CurrencyExchangeRate: 'Kurs za valutu',
        PrintBarcode: 'Odštampaj barkod',
        ClientAppPort: 'Port klijentske aplikacije',
        Status: 'Status',
        AssetStatus1: 'Aktivan',
        AssetStatus0: 'Neaktivan',
        CurrentValueZero: 'Sadašnja vrednost = 0',
        CurrentValueGraterThanZero: 'Sadašnja vrednost veća od 0',
        Steal: 'Krađa',
        DocumentDate: 'Datum dokumenta',
        JBKJS: 'JBKJS',
        LegalEntityFormENTREPRENEUR: 'Preduzetnik',
        LegalEntityFormLLC: 'Društvo sa ograničenom odgovornošću',
        LegalEntityFormJOINT_STOCK: 'Akcionarsko društvo',
        LegalEntityFormPARTNERSHIP: 'Ortačko društvo',
        LegalEntityFormAGRICULTURAL_HOLDING: 'Poljoprivredno gazdinstvo',
        LegalEntityFormPUBLIC_ENTERPRISE: 'Javno preduzeće',
        LegalEntityFormINSTITUTION: 'Ustanova',
        BarcodesPrint: 'Odštampaj barkodove',
        ItemsWithValue: 'Stavke koje učestvuju u obračunu',
        BuildingGeneralData: 'Opšti podaci o objektu',
        Municipality: 'Opština',
        PropertyTax: 'Porez na imovinu',
        TypeOfConstruction: 'Tip gradnje',
        TYPE_OF_CONSTRUCTION: 'Tip gradnje',
        CadastralParcelNumber: 'Broj katastarske parcele',
        FixedAssetTehData: 'Tehnički podaci osnovnog sredstva',
        Manufacturer: 'Proizvođač',
        FactoryCode: 'Fabrička oznaka',
        MadeIn: 'Poreklo',
        RegisterNumber: 'Registarski broj',
        EngineNumber: 'Broj motora',
        Vin: 'Broj šasije',
        ProductionYear: 'Godina proizvodnje',
        GlassSurface: 'Površina stakla',
        ShowBookQuantity: 'Prikaži stanje po knjigama',
        Synthetic: 'Sintetika',
        GroupAssetsMove: 'Grupni prenos sredstava',
        MoveAllAssetsFromOneAccountableToOther:
          'Premeštanje svih sredstava sa jednog računopolagača na drugog',
        OldAccountable: 'Stari računopolagač',
        NewAccountable: 'Novi računopolagač',
        AddItemsInBulkInDocument: 'Grupno dodavanje stavki dokumenta',
        AddItemsInBulk: 'Dodaj stavke grupno',
        MachineType: 'Vrsta mašine',
        VehicleRegistrationType: 'Tip registrovanja',
        VehicleRegistrationTypeNO_REGISTRATION: 'Ne registruje se',
        VehicleRegistrationTypeTEMPORARY_REGISTRATION: 'Registruje se',
        VehicleRegistrationTypePERMANENT_REGISTRATION: 'Trajna registracija',
        GlassType: 'Vrsta stakla',
        TaxDeprecationCalculation: 'Poreska amortizacija',
        FilterByType: 'Filtriraj po tipu',
        FilterByTypeOfConstruction: 'Filtriraj po tipu gradnje',
        FilterByGlassSurfaceGreaterThan: 'Površina stakla veća od',
        FilterByPropertyTax: 'Filtriraj po porezu na imovinu',
        FilterByVehicleRegistrationType: 'Filtriraj po tipu registrovanja',
        FilterByMachineType: 'Filtriraj po vrsti mašine',
        FilterBySourceOfSupply: 'Filtriraj po izvoru nabavke',
        DeleteDocument: 'Obriši dokument',
        DeleteItem: 'Obriši stavku',
        DeleteItemContentText: 'Da li ste sigurni da želite da obrišete stavku?',
        WarehouseMan: 'Magacioner',
        ACCOUNT: 'Konto',
        ACCOUNTABLE: 'Računopolagač',
        TotalPurchaseValue: 'Nabavna vrednost',
        TotalCurrentValue: 'Sadašnja vrednost',

        GlassTypes: 'Vrsta stakala',
        AddGlassType: 'Dodavanje vrste stakla',
        EditGlassType: 'Izmena podataka vrste stakla',

        InitialValueByBooks: 'Početno stanje po knjigama',
        ValueByBooks: 'Stanje po knjigama',
        InitialValueByList: 'Stanje po popisu',
        LastValueByList: 'Stanje posle izvršenog popisa',
        BookQuantityQ: 'Stanje po knjigama - količina',
        PurchaseValueByList: 'Nabavna po popisu',
        DeprecatedValueByList: 'Otpisna po popisu',
        CurrentValueByList: 'Sadašnja vrednost po popisu',
        RealQuantityQ: 'Stanje po popisu - količina',
        Surplus: 'Višak',
        Deficit: 'Manjak',
        ExpenseSale: 'Rashod / Otuđenje',
        InitialQuantity: 'Početna količina',
        InitialPurchaseValue: 'Početna nabavna vrednost',
        InitialDeprecatedValue: 'Početna otpisana vrednost',
        InitialCurrentValue: 'Početna sadašnja vrednost',
        Photo: 'Fotografija',
        UploadPhoto: 'Dodajte fotografiju',
        ChangePhoto: 'Zamenite fotografiju',
        CalculationTotal: 'Ukupno za obračun',
        TotalDocumentValue: 'Ukupno za ceo dokument',
        DocumentsTotal: 'Ukupno za prikazana dokumenta',
        FilterTotal: 'Ukupno po zadatim filterima',
        AccountNumberOfDigits: 'Broj cifara konta',
        SpentValue: 'Rashodovana vrednost',
        SpentQuantity: 'Rashodovana količina',
        FilterByYear: 'Pretraga po godini',
        Year: 'Godina',

        Contract: 'Ugovor',
        Contracts: 'Ugovori',
        ContractSearch: 'Pretraga ugovora',
        ContractNumber: 'Broj ugovora',
        Contractor: 'Partner',
        ConclusionContractDate: 'Datum zaključenja ugovora',
        AddContract: 'Dodavanje ugovora',
        ConclusionDateFrom: 'Datum zaključenja od',
        ConclusionDateTo: 'Datum zaključenja do',
        ExpirationDate: 'Datum isteka',
        ContractExpirationDate: 'Rok važenja ugovora',
        ExpirationDateFrom: 'Datum isteka od',
        ExpirationDateTo: 'Datum isteka do',
        ContractType: 'Tip ugovora',
        ExpiringContractsIn30: 'Ugovori koji ističu za manje od 30 dana',
        Income: 'Ulazni',
        Outcome: 'Izlazni',
        INBOUND: 'Ulazni',
        OUTBOUND: 'Izlazni',
        In: 'Ulaz',
        Out: 'Izlaz',
        Saldo: 'Saldo',
        DeliveryTimeInDays: 'Rok isporuke u danima',
        ComplaintDeadlineInDays: 'Rok reklamacije u danima',
        WarrantyPeriodInMonths: 'Garantni rok u mesecima',
        Items: 'Stavke',
        ContractItemSearch: 'Pretraga stavki',
        ValueWithoutVat: 'Vrednost bez PDV-a',
        ValueWithVat: 'Vrednost sa PDV-om',
        AddContractItem: 'Dodavanje stavke',
        EditContractItem: 'Izmena stavke',
        ViewContractItem: 'Pregled stavke',
        DateFrom: 'Od datuma',
        DateTo: 'Do datuma',
        ContractSubject: 'Predmet ugovora',
        CurrencyCodebook: 'Šifarnik valuta',
        ForeignExchange: 'Devizni ugovor',
        ForeignCurrencies: 'Devizne valute',
        DinarCurrency: 'Dinarska valuta',
        DocumentValue: 'Vrednost dokumenta',

        Product: 'Artikal',
        DeleteContractItemConfirmation:
          'Da li ste sigurni da želite da izbrišete stavku ugovora?',
        DeleteContractItemTitle: 'Brisanje stavke ugovora',
        Referent: 'Referent',
        AgreedAmountWithVat: 'Ugovoreni iznos sa pdv',
        AgreedAmountWithoutVat: 'Ugovoreni iznos bez pdv',
        WarningAmount: 'Iznos upozorenja',
        WarningAmountWithVat: 'Iznos upozorenja sa pdv',
        WarningAmountWithoutVat: 'Iznos upozorenja bez pdv',
        WithVat: 'Sa pdv',
        WithoutVat: 'Bez pdv',
        WarningDate: 'Datum upozorenja',
        BestPayBeforeDays: 'Broj dana valute',
        DeactivateContract: 'Deaktiviraj ugovor',
        DeactivationDate: 'Datum deaktivacije',
        DeactivationNote: 'Obrazloženje deaktivacije',
        ContractDeactivated: 'Ugovor je deaktiviran.',
        Explanation: 'Obrazloženje',
        ParticipateInContractRealization: 'Učestvuje u realizaciji ugovora',
        Realization: 'Realizacija',
        Utilized: 'Realizovano',
        Agreed: 'Ugovoreno',
        RemainingAmount: 'Preostali iznos',
        RealizationPercentage: 'Procenat realizacije',
        ContractDurationInDays: 'Broj dana važenja',
        DiscountPercentage: 'Rabat',
        BasicDiscountPercentage: 'Osnovni rabat u %',
        UnitPriceWithoutVat: 'Cena bez PDV-a',
        UnitPriceWithVat: 'Cena sa PDV-om',
        SerialNumber: 'Serijski broj',
        CodeAndName: 'Šifra i naziv',
        ViewProduct: 'Pregled artikla',

        Currencies: 'Valute',
        CurrencyCode: 'Oznaka valute',
        CurrencyNumericCode: 'Šifra valute',
        AddCurrency: 'Dodaj valutu',
        EditCurrency: 'Izmeni valutu',
        ViewCurrency: 'Pregled valute',

        Manufacturers: 'Proizvođači',
        AddManufacturer: 'Dodaj proizvođača',
        EditManufacturer: 'Izmeni proizvođača',
        ViewManufacturer: 'Pregled proizvođača',
        IsActive: 'Aktivan',

        BillsOfExchange: 'Menice',
        BillOfExchangeNumber: 'Broj menice',
        ItemNumber: 'Broj sredstva',
        BillOfExchangeItem: 'Sredstvo menice',
        Received: 'Primljena',
        Given: 'Data',
        ItemDate: 'Datum sredstva',
        ItemAmount: 'Iznos sredstva',
        ItemType: 'Vrsta sredstva',
        ViewBillOfExchange: 'Pregled menice',
        EditBillOfExchange: 'Izmeni menicu',
        AddBillOfExchange: 'Dodaj menicu',
        AuthorizationNumber: 'Broj ovlašćenja',
        AuthorizationDate: 'Datum ovlašćenja',
        RegistrationDate: 'Datum registracije',
        ValidUntil: 'Rok važnosti',
        Amount: 'Iznos',
        ReturnDate: 'Datum vraćanja',
        ReturnDateWarning: 'Upozorenje za datum vraćanja',
        DeleteBillOfExchangeTitle: 'Brisanje menice',
        DeleteBillOfExchangeConfirmation:
          'Da li ste sigurni da želite da obrišete menicu',
        DueDate: 'Datum valute',
        CurrencyDateFrom: 'Datum valute od',
        CurrencyDateTo: 'Datum valute do',

        BankGuarantee: 'Bankarske garancije',
        BankGuaranteeNumber: 'Broj bankarske garancije',
        BankGuaranteeItem: 'Sredstvo garancije',
        ViewBankGuarantee: 'Pregled garancije',
        EditBankGuarantee: 'Izmeni garanciju',
        AddBankGuarantee: 'Dodaj garanciju',
        DeleteGuaranteeTitle: 'Brisanje garancije',
        DeleteGuaranteeConfirmation: 'Da li ste sigurni da želite da obrišete garanciju',
        ContractWithBankNumber: 'Broj ugovora sa bankom',

        DeleteContactPersonTitle: 'Brisanje kontakt osobe',
        DeleteContactPersonConfirmation:
          'Da li ste sigurni da želite da obrišete kontakt osobu',
        ConclusionDate: 'Datum zaključenja',
        Returned: 'Vraćena',
        CopyContract: 'Kopiraj ugovor',
        ChooseContractToToCopyItems: 'Izabrati ugovor sa kog želite da kopirate stavke',
        CopyDocumentItems: 'Kopiraj stavke',
        ContainsProduct: 'Sadrži artikal',

        Supplies: 'Zalihe',
        Area: 'Zona',
        StorageUnitAddress: 'Skladišna jedinica',
        StockList: 'Lager lista',
        WarehouseSelection: 'Izbor magacina',
        State: 'Stanje',
        CORRECT: 'Ispravan',
        UNTESTED: 'Netestiran',
        FAULTY: 'Neispravan',
        PurchasePrice: 'Nabavna cena',
        SalePrice: 'Prodajna cena',
        SaleValue: 'Prodajna vrednost',
        InStock: 'Na stanju',
        DocumentPrint: 'Štampa dokumenta',
        Place: 'Mesto',
        TrafficDate: 'Datum prometa',
        WarehouseName: 'Naziv magacina',
        ShowPrices: 'Prikaži cene',
        HidePrices: 'Sakrij cene',
        Number: 'Broj',
        ListNumber: 'Broj liste',
        ReceivedBy: 'Robu primio',
        GivenBy: 'Robu izdao',
        Confirmed: 'Potvrđen',
        InPreparation: 'U pripremi',
        FromWarehouse: 'Iz magacina',
        ToWarehouse: 'U magacin',
        InventoryListNumber: 'Broj popisne liste',
        // Errors
        NoInternetError: 'Proverite internet konekciju',
        DOCUMENT_EXISTS: 'Dokument sa tim brojem već postoji',
        PRODUCT_CODE_ALREADY_EXISTS: 'Šifra artikla već postoji!',
        LEGAL_ENTITY_DOES_NOT_HAVE_AT_LEAST_REG_NUM_TAX_ID_PERSONAL_IDENT_NUM:
          'Polja: Matični broj, PIB ili JMBG moraju biti popunjena',
        REAL_QUANTITY_NULL: 'Količina po popisu mora biti definisana',
        BOOK_QUANTITY_NULL: 'Količina po knjigama mora biti definisana',
        DOCUMENT_ON_LIST_CHANGE_FORBIDDEN:
          'Nije moguće otključavati/zaključivati dokument vezan za popisnu listu. Ove akcije moguće je izvršiti jedino otključavanjem/zaključavinjem popisne liste što automatski povlači iste akcije nad povezanim dokumentima',
        INVENTORY_NULL: 'Popis mora biti definisan',
        EXPENSE_DOC_Q_EXCEEDS_INV_ITEM_DEFICIT:
          'Količina rashodna na stavci dokumenta premašuje sračunatu razliku između količine po popisu i količine po knjigama za odgovarajuću stavku popisa',
        EXPENSE_DOC_Q_EXCEEDS_INV_ITEM_EXPENSE_Q:
          'Količina rashodna na stavci dokumenta premašuje unetu količinu rashoda za odgovarajuću stavku popisa',
        SURPLUS_Q_GREATER_THAN_ITEM_Q_DIFFERENCE:
          'Uneta količina viška premašuje sračunatu razliku između količine po popisu i količine po knjigama',
        EXPENSE_AND_MINUS_SUM_GREATER_THAN_ITEM_Q_DIFFERENCE:
          'Unete količine rashoda i manjka na stavci popisa moraju u zbiru biti jednake sračunatoj razlici između količine po popisu i količine po knjigama',
        MINUS_Q_GREATER_THAN_ITEM_Q_DIFFERENCE:
          'Uneta količina manjka na stavci popisa mora biti jednaka sračunatoj razlici između količine po popisu i količine po knjigama',
        EXPENSE_Q_GREATER_THAN_ITEM_Q_DIFFERENCE:
          'Uneta količina rashoda na stavci popisa mora biti jednaka sračunatoj razlici između količine po popisu i količine po knjigama',
        SURPLUS_FORBIDDEN_REAL_Q_LESS_THAN_BOOK_Q:
          'Nije moguće definisati višak - količina po popisu je manja od količine po knjigama',
        MINUS_FORBIDDEN_REAL_Q_GREATER_THAN_BOOK_Q:
          'Nije moguće definisati manjak - količina po popisu je veća od količine po knjigama',
        EXPENSE_FORBIDDEN_REAL_Q_GREATER_THAN_BOOK_Q:
          'Nije moguće definisati rashod - količina po popisu je veća od količine po knjigama',
        SURPLUS_Q_LESS_OR_EQUAL_ZERO:
          'Uneta količina viška na stavci mora biti pozitivan broj',
        MINUS_Q_LESS_OR_EQUAL_ZERO:
          'Uneta količina manjka na stavci mora biti pozitivan broj',
        EXPENSE_Q_LESS_OR_EQUAL_ZERO:
          'Uneta količina rashoda na stavci mora biti pozitivan broj',
        DEFICIT_NOT_COVERED_BY_DOCUMENTS:
          'Količine manjka i/ili rashoda unete na stavci/stavkama popisa nisu pokrivene odgovarajućim dokumentima',
        SURPLUS_NOT_COVERED_BY_DOCUMENTS:
          'Količine viška definisane na stavci/stavkama popisa nisu pokrivene količinama',
        MISSING_DOCUMENT:
          'Nedostaju dokumenti koji pokrivaju razliku u količinama po knjigama i po popisu',
        TOTAL_SUM_OF_QUANTITIES_ON_ITEM_INVALID:
          'Apsolutna razlika između količine po popisu i količine po knjigama mora biti u skladu sa definisanim količinama manjka/rashoda/viška',
        INVENTORY_LIST_ITEM_NOT_FOUND: 'Stavka popisne liste nije pronađena',
        INVALID_INVENTORY_LIST_STATUS:
          'Popisna lista nije u odgovarajućem statusu (stanju)',
        INVALID_INVENTORY_LIST_DOCUMENTS:
          'Popisna lista nije povezana sa odgovarajućim dokumentima',
        WH_DOCUMENTS_CAN_NOT_HAVE_NEGATIVE_ON_STOCK:
          'Za otključavanje dokumenta, potrebno je obrisati sledeća dokumenta:\n{{metadata}}',
        quantityPositiveOrZero: 'Količina mora biti pozitivan broj',
        CANNOT_REGENERATE_INVENTORY_RELATED_DOCUMENT_CONCLUDED:
          'Nije moguće regenerisati popis jer postoje zaključeni dokumenti vezani za popisne liste',
        CANNOT_REGENERATE_INVENTORY_STALE_ITEMS_ON_CONCLUDED_LISTS:
          'Nije moguće regenerisati popis. Na zaključenim popisnim listama postoje nekonzistenti podaci (stavke tih listi se ne poklapaju sa trenutnim stanjem zaliha u sistemu)',

        EnginePower: 'Snaga motora',
        GarageNumber: 'Garažni broj',
        Leasing: 'Lizing',
        LeasingNO_LEASING: 'Bez lizinga',
        LeasingFINANCIALLY: 'Finansijski',
        LeasingOPERATIONAL: 'Operativni',

        FA_DOCUMENT_NUMBER_EXISTS: 'Uneti inventarski broj već postoji',
        CURRENT_YEAR_DOES_NOT_MATCH_DOC_DATE:
          'Godina sa dokumenta se ne slaže sa datumom dokumenta, različite su.',

        ReportSIByAccount: 'Sitan inventar po kontu',
        ReportSIByAccountReport: 'Izveštaj sitnog inventara po kontu',
        ReportsSI: 'Sitan inventar - izveštaji',
        ReportSI: 'Sitan inventar - izveštaj',
        WarehousePurpose: 'Svrha magacina',
        AccountableNotPossibleOnWarehousePurposeSI_SUPPLY:
          'Izveštaj nije moguće kreirati. Na zalihama ne postoji računopolagač',

        YearlyProcurementPlans: 'Godišnji planovi',
        ProcurementStatusDRAFT: 'U pripremi',
        ProcurementStatusACTIVE: 'Aktivan',
        ProcurementStatusCONCLUDED: 'Konačan',
        Version: 'Verzija',
        AddProcurementPlan: 'Dodaj novi plan',

        YearlyPlan: 'Godišnji plan',
        YEARLY_PLAN: 'Godišnji plan',
        AnnualNeeds: 'Godišnje potrebe',
        ANNUAL_NEEDS: 'Godišnje potrebe',

        ProcurementPlanPart: 'Predmet nabavke',
        ViewProcurementPlanPart: 'Pregled predmeta nabavke',
        EditProcurementPlanPart: 'Izmena predmeta nabavke',
        AddProcurementPlanPart: 'Dodaj predmet nabavke',

        PlanPositionPC: 'Pozicija iz plana JN',
        PlanPositionNonPC: 'Pozicija iz plana nabavki na koji se zakon ne odnosi',
        CreateNewVersion: 'Napravi novu verziju',

        PlannedAmountWithVat: 'Planirani iznos sa PDV',
        PlannedAmountWithoutVat: 'Planirani iznos bez PDV',

        PredictedValueWithoutVat: 'Procenjeni iznos bez PDV',
        PredictedValueWithVat: 'Procenjeni iznos sa PDV',

        GroupingElementCount: 'Broj elementa grupisanja',
        GroupingElementCount3: '3',
        GroupingElementCount4: '4',
        GroupingElementCountTHREE: '3',
        GroupingElementCountFOUR: '4',

        ProcurementGroups: 'Unos potreba OJ',
        ProcurementPlan: 'Predmet nabavke',
        YearlyPlanPosition: 'Pozicija u planu JN',
        YearlyPlanLowPosition: 'Pozicija nabavki na koje se zakon ne odnosi',
        Jurisdiction: 'Nadležnost',
        WithoutJurisdiction: 'Bez nadležnosti',
        EstimatedValue: 'Procenjena vrednost za pokretanje nabavke',
        EstimatedValueShort: 'Procenjena vrednost',
        PaidInPlannedYearFromPastYearWithoutVat:
          'Plaćanje u {{year}}. godini bez PDV za nabavke iz prethodne godine',
        PaidInPlannedYearFromPastYearWithVat:
          'Plaćanje u {{year}}. godini sa PDV za nabavke iz prethodne godine',
        PaidInNextYear: 'Plaćanje u {{year}}. godini bez PDV',
        PaidInPlannedYearWithVat: 'Plaćanje u {{year}}. godini sa PDV',
        PaidInPlannedYearWithoutVat: 'Plaćanje u {{year}}. godini bez PDV',
        TotalForPaymentWithoutVat: 'Ukupno plaćanje za {{year}}. godinu bez PDV',
        TotalForPaymentWithVat: 'Ukupno plaćanje za {{year}}. godinu sa PDV',
        AccountPastYear: 'Konto iz prethodne godine',
        YES: 'Da',
        NO: 'Ne',
        ProcurementEstimateGroups: 'Grupe potreba po OJ',
        ProcurementEstimateGroup: 'Grupa potreba po OJ',
        EditProcurementEstimateGroup: 'Izmeni grupu potreba',
        StatusChange: 'Promena statusa',
        ChooseProcurementStatus: 'Izaberite status',
        AddProcurementEstimateForm: 'Forma za unos potreba',
        EditProcurementEstimateForm: 'Forma za izmenu potrebe',
        AddProcurementEstimateGroup: 'Dodaj grupu potreba',
        GroupedEstimates: 'Rekapitulacija po kontima',
        Estimates: 'Potrebe po kontu',
        DeleteEstimate: 'Brisanje potrebe po kontu',
        DeleteEstimateDesc: 'Da li ste sigurni da želite da izbrišete potrebu po kontu?',
        DeleteYearlyPlanItem: 'Brisanje stavke plana',
        DeleteYearlyPlanItemDesc:
          'Da li ste sigurni da želite da izbrišete stavku plana?',
        ValueWithoutVatFirstVersionPastYear:
          'JN bez PDV januar {{year}}. prva verzija plana iz {{year}}. godine',
        ValueWithoutVatLastVersionPastYear:
          'JN bez PDV decembar {{year}}. poslednja verzija plana iz {{year}}. godine',
        EstimatedTransferredValueWithoutVatPastYear:
          'Procenjene Preuzete obaveze iz {{year}}.',
        EstimatedValueWithoutVatThisYear:
          'Nabavke za plaćanje u {{year}}. (planirana potrošnja u tekućoj godini po novim ugovorima)',
        ValueWithoutVatThisYear: 'Finansijski plan JN bez PDV {{year}}',
        PastYearDifferenceFirstVersion:
          '{{year}} – {{lastYear}} (razlika plana za tekuću i prethodnu godinu)',
        PastYearDifferenceLastVersion:
          '{{year}} – {{lastYear}} (razlika plana za tekuću i prethodnu godinu)',
        PlannedValueThisYear: 'Finansijski plan {{year}} ukupno sa PDV',
        RealTransferredValueWithoutVatPastYear:
          'Preuzete obaveze iz {{year}} (preostali iznos sredstava po aktivnim zaključenim ugovorima iz prethodnih godina)',
        ValueForPaymentThisYear: 'Nabavke za plaćanje u {{year}}.',
        AnalyticallyExpensePlan: 'Analitički plan rashoda bez PDV {{year}}',
        AnalyticallyFinancialPlan: 'Analitički fin. plan bez PDV {{year}}',
        AnalyticallyValueWithVatThisYear: 'Analitički plan {{year}} ukupno sa PDV',

        ProcurementYearlyPlans: 'Godišnji plan',
        AddProcurementYearlyPlan: 'Dodaj godišnji plan',
        ProcurementYearlyPlanGroups: 'Godišnji planovi',
        EditProcurementYearlyPlan: 'Izmena godišnjeg plana',
        YearlyPlanItems: 'Stavke plana',
        YearlyPlanItemsGrouped: 'Rekapitulacija plana',
        YearlyPlanItemsGroupedByOrun: 'Rekapitulacija plana po nadležnosti',
        ProcurementYearlyPlan: 'Godišnji plan',

        AuditLogs: 'Revizija logova',
        EntityClass: 'Klasa entiteta',
        User: 'Korisnik',
        Time: 'Vreme',
        Action: 'Akcija',
        Entity: 'Entitet',
        EntityIdentifier: 'Identifikator entiteta',
        OnlyMyLogs: 'Samo moja logovanja',
        Identifier: 'Identifikator',
        EntityIdentifierRepresentation: 'Reprezentacija identifikatora resursa',
        IpAddress: 'IP adresa',
        AuditLogItems: 'Stavke revizije',
        AttributeName: 'Naziv atributa',
        OldValue: 'Stara vrednost',
        NewValue: 'Nova vrednost',
        ActionEDIT: 'Izmena',
        ActionCREATE: 'Kreiranje',
        ActionLOG_IN: 'Logovanje',
        ActionDELETE: 'Brisanje',
        ActionPREVIEW: 'Pregled',
        ActionLOGIN_FAILED: 'Neuspešno logovanje',
        EntityClassUser: 'Korisnik',
        EntityClassWHDocument: 'Dokument',
        EntityClassWHDocumentItem: 'Stavka dokumenta',
        EntityClassProduct: 'Artikal',
        From: 'od',
        To: 'do',
      },
    },
    sr_RS_Cyrl: {
      translations: {
        Yes: 'Да',
        No: 'Не',
        Save: 'Сачувај',
        Accept: 'Прихвати',
        Confirm: 'Потврди',
        Cancel: 'Откажи',
        AddInput: 'Унеси',
        Choose: 'Изабери',
        Delete: 'Обриши',
        WithoutPrivileges: 'Корисник нема права приступа овој страници!',
        NumberOfRows: 'Број редова',
        OrderNumber: 'Рб',
        Codebooks: 'Шифарници',
        ItemClassification: 'Класификација артикала',
        NavigationMenu: 'Навигациони мени',
        Search: 'Претрага',
        Close: 'Затвори',
        AllRows: 'Сви',
        NoResult: 'Нема резултата',
        number: 'број',

        Users: 'Корисници',
        UserName: 'Корисничко име',
        Name: 'Назив',
        FirstName: 'Име',
        LastName: 'Презиме',
        Email: 'Емаил адреса',
        Role: 'Улога',
        Password: 'Лозинка',
        RepeatPassword: 'Поновите лозинку',
        AddUser: 'Додавање корисника',
        EditUser: 'Измена података корисника',
        CancelEditMode: 'Поништи режим измене',
        CancelAddMode: 'Поништи режим додавања',
        HomePage: 'Почетна страна',
        ToggleSidebar: 'Прикажи/сакриј бочну траку',

        Organizations: 'Организације',
        RegistrationNumber: 'Матични број',
        TaxId: 'ПИБ',
        AddOrganization: 'Додавање организације',
        EditOrganization: 'Измена података организације',

        UnitOfMeasure: 'Јединица мере',
        UnitsOfMeasure: 'Јединице мере',
        AddUnitOfMeasure: 'Додавање јединице мере',
        EditUnitOfMeasure: 'Измена података јединице мере',

        ClassificationType: 'Тип класификације',
        ClassificationTypes: 'Типови класификације',
        Description: 'Опис',
        Primary: 'Примаран',
        AddClassificationType: 'Додавање типа класификације',
        EditClassificationType: 'Измена података типа класификације',

        Classification: 'Класификација',
        Mandatory: 'Обавезно',
        AddClassification: 'Додавање класификације',
        AddToClassification: 'Додавање класификацији',
        EditClassification: 'Измена података класификације',
        UserDefinedCode: 'Кориснички дефинисан код',
        AddAttribute: 'Додај артикал',

        MainProducts: 'Примарни артикли',
        MainProduct: 'Примаран артикал',
        MainProductList: 'Листа примарних артикала',
        MinStockLevel: 'Минимално залиха',
        ClassificationName: 'Име класификације',
        FilterByName: 'Филтрирај по имену',
        FilterByCode: 'Филтрирај по коду',
        FilterByInventoryNumber: 'Филтрирај по инвентарском броју',
        AddMainProduct: 'Додавање примарног артикла',
        ViewMainProduct: 'Преглед примарног артикла',
        EditMainProduct: 'Измена података примарног артикла',
        AddProductClassification: 'Додај класификацију',
        MainProductClassifications: 'Класификације примарног артикла',

        Products: 'Артикли',
        ProductName: 'Назив артикла',
        Code: 'Шифра',
        OldCode: 'Стара шифра',
        AddProduct: 'Додавање артикла',
        EditProduct: 'Измена података артикла',

        Wms: 'Магацинско пословање',
        Warehouse: 'Магацин',
        SelectAllWarehouses: 'Означи све магацине',
        Warehouses: 'Магацини',
        Purpose: 'Сврха',
        Type: 'Тип',
        Edit: 'Измени',
        AddWarehouse: 'Додај магацин',
        AddWarehouseTitle: 'Додавање магацина',
        WarehouseConfiguration: 'Конфигурација магацина',
        EditWarehouse: 'Измена података магацина',

        Address: 'Адреса',
        StorageUnit: 'Складиштна јединица',
        EditStorageUnit: 'Додај јединицу складиштења',
        AddStorageUnit: 'Измени јединицу складиштења',

        OrganizationalUnit: 'Организациона јединица',
        OrganizationalUnits: 'Организационе јединице',
        AddOrganizationalUnit: 'Додавање организационе јединице',
        AddToOrganizationalUnit: 'Додавање организационој јединици',
        EditOrganizationalUnit: 'Измена података организационе јединице',

        Locale: 'Језик',
        Administration: 'Администрација',

        AddOrun: 'Додај организациону јединицу',

        Assets: 'Основна средства',
        Asset: 'Основно средство',
        InventoryNumber: 'Инвентарски број',
        ExternalInventoryNumberShort: 'Стари инв. бр.',
        Date: 'Датум',
        ChooseDate: 'Изабери датум',
        EditAsset: 'Измена података основног средства',
        EditAssetTitle: 'Картица основног средства',
        AddAsset: 'Додај основно средство',
        AddAssetTitle: 'Додавање основног средства',
        Barcode: 'Бар-код',
        Barcodes: 'Бар-кодови',
        AddBarcode: 'Додај бар-код',
        Value: 'Вредност',
        BarcodeType: 'Тип бар-кода',
        PurchaseValue: 'Набавна вредност',
        PurchaseValueWithoutVat: 'Набавна вредност без пдв',
        PurchaseValueWithVat: 'Набавна вредност са пдв',
        VatPercentage: 'ПДВ %',
        LifespanYear: 'Животни век - година',
        DeleteBarcodeConfirmation: 'Да ли сте сигурни да желите да избришете бар-код?',
        DeleteBarcodeTitle: 'Брисање бар-кода',
        FixedAssetEvidence: 'Евиденција',
        FixedAssetList: 'Листа основних средстава',

        LegalEntities: 'Правна лица',
        LegalEntity: 'Правно лице',
        LegalForm: 'Правна форма',
        AddressBook: 'Адресар',
        PartnerListView: 'Приказ листе партнера',
        AddLegalEntity: 'Додавање партнера',
        AddLegalEntityFromNBS: 'Додавање партнера са НБС',
        AddLegalEntityManually: 'Ручно додавање партнера',
        LegalEntityEdit: 'Измена партнера',
        LegalEntityAddress: 'Адреса седишта',
        LegalEntityHouseNumber: 'Број адресе седишта',
        LegalEntityTown: 'Град седишта',
        LegalEntitySearch: 'Претрага партнера',
        NameFilter: 'Претрага по називу',
        TaxIdFilter: 'Претрага по ПИБ-у',
        RegistrationNumberFilter: 'Претрага по матичном броју',
        BusinessActivity: 'Шифра делатности',
        PersonalIdentificationNumber: 'ЈМБГ',
        IsResponsiblePerson: 'Одговорно лице?',
        IsDirector: 'Директор?',
        AddContactPerson: 'Додавање контакт особе',
        EditContactPerson: 'Измена контакт особе',
        PostalCode: 'Поштански број',
        Website: 'Веб сајт',
        Phone: 'Телефон',
        Group: 'Група',
        Groups: 'Групе',
        AddGroup: 'Додавање групе',
        EditGroup: 'Измена групе',
        GroupType: 'Тип групе',
        RequiredFieldsError: 'Поља означена * су обавезна',
        FetchFromNbs: 'Преузми податке са НБС',
        ContactInformation: 'Контакт подаци',
        ContractEdit: 'Измена уговора',
        BankAccounts: 'Текући рачуни',
        Bank: 'Банка',
        BankAccount: 'Текући рачун',
        EditBankAccount: 'Измена текућег рачуна',
        AddBankAccount: 'Додавање текућег рачуна',
        MainBankAccount: 'Примарни рачун',
        Notes: 'Напомене',
        AddNote: 'Додавање напомене',
        EditNote: 'Измена напомене',
        CreatedBy: 'Саставио',
        Subject: 'Предмент',
        ShowLocationOnMap: 'Приказати локацију на мапи',
        AddressNotFound: 'Адреса није пронађена',
        Documentation: 'Документација',
        LegalEntitiesGroups: 'Групе правних лица',
        NBSSearch: 'Претрага партнера на НБС',

        Active: 'Активан',
        Inactive: 'Неактиван',

        Document: 'Документ',
        Documents: 'Документи',
        DocumentNumber: 'Број документа',
        DocumentType: 'Тип документа',

        FixedAsset: 'Основно средство',
        FixedAssets: 'Основна средства',
        Add: 'Додај',
        Supplier: 'Добављач',
        SupplierName: 'Назив добављача',
        AddDocumentTitle: 'Додавање документа',
        AddDocument: 'Додај документ',
        PreviewDocument: 'Преглед документа',
        DocumentItems: 'Ставке документа',
        AddDocumentItem: 'Додај ставку документа',
        EditDocumentItem: 'Измени ставку документа',
        DeleteDocumentItemConfirmation:
          'Да ли сте сигурни да желите да избришете ставку документа?',
        DeleteDocumentConfirmation: 'Да ли сте сигурни да желите да избришете документ?',
        DeleteDocumentItemTitle: 'Брисање ставке документа',
        AmortizationGroup: 'Амортизациона група',
        AmortizationSubGroup: 'Амортизациона под група',
        AmortizationGroupThirdLevel: 'Алинеја',
        AmortizationGroups: 'Амортизационе групе',
        AmortizationTaxGroups: 'Пореске групе',
        AmortizationTaxGroup: 'Пореска група',
        AmortizationTaxSubGroup: 'Пореска под група',
        ParentGroup: 'Родитељска група',
        EditAmortizationGroup: 'Измена амортизационе групе',
        AddAmortizationGroup: 'Додавање аморзитационе групе',
        AddAmortizationTaxGroup: 'Додавање пореске групе',
        EditAmortizationTaxGroup: 'Измена пореске групе',
        AmortizationData: 'Обрачунски подаци',
        AmortizationRate: 'Стопа амортизације',
        DocumentItemDetails: 'Подаци о ставци докуемнта',
        FixedAssetDetails: 'Подаци о основном средству',
        ActivationDate: 'Датум активације',
        ActivationDateFrom: 'Датум активације до',
        ActivationDateTo: 'Датум активације од',
        FinancialCard: 'Финансијска картица',
        DepreciatedValue: 'Отписана вредност',
        TotalDepreciatedValue: 'Отписана вр. пре расхода',
        CurrentValue: 'Садашња вредност',

        Employees: 'Запослени',
        Employee: 'Запослен',
        EvidenceNumber: 'Евиденциони број',
        PersonalIdentityNumber: 'ЈМБГ',
        GivenName: 'Име',
        FamilyName: 'Презиме',
        AddEmployee: 'Додавање запосленог',
        EditEmployee: 'Измена података запосленог',

        Locations: 'Локације',
        Representation: 'Репрезентација',
        ViewLocation: 'Преглед локације',
        AddLocation: 'Додавање локације',
        EditLocation: 'Измена података локације',

        BasicInfo: 'Општи подаци',
        LocationChange: 'Кретање средстава',
        Location: 'Локација',
        LatestLocation: 'Тренутна локација',

        Accountable: 'Рачунополагач',
        TotalPurchaseValue: 'Набавна вредност',
        TotalCurrentValue: 'Садашња вредност',
        LatestAccountable: 'Тренутни рачунополагач',

        StorageUnitsByArea: 'Јединице складиштења унутар зоне',
        AreaType: 'Тип зоне складиштења',
        AddArea: 'Додај зону складиштења',
        WarehouseAreas: 'Зоне у магацину',
        OrunsWithAllowedStorageAccess:
          'Организационе јединице са дозвољеним приступом скалдишту',

        AssetList: 'Попис',
        AssetLists: 'Пописне листе',
        AddAssetList: 'Додавање пописне листе',
        PreviewAssetList: 'Преглед пописне листе',
        BookQuantity: 'Стање књиге',
        RealQuantity: 'Стање пописа',
        AssetListItems: 'Ставке пописне листе',
        AssetListRecapitulationOnDate: 'Пописна листа основних средстава на дан',
        AssetListSmallInventoryOnDate: 'Пописна листа ситног инвентара на дан',
        ProcessedAssetListRecapitulation: 'Попис основних средстава - обрађена листа',
        ProcessedAssetListSmallInventory: 'Попис ситног инвентара - обрађена листа',

        Change: 'Промени',
        ChangeOrganization: 'Промени организацију',
        LogOut: 'Одјави се',
        OrganizationChange: 'Променa организацијe',
        LoggedUserOrganizations: 'Листа организација улоговановог корисника',

        GoToHomepage: 'Иди на почетну страну',
        WrongOrganizationMessage: 'Тражени ресурс не припада организацији',

        Account: 'Конто',
        Accounts: 'Конта',
        AddAccount: 'Додај конто',
        EditAccount: 'Измени конто',
        ViewAccount: 'Прегледај конто',
        PurchaseDateFrom: 'Датум набавке од',
        PurchaseDateTo: 'Датум набавке до',
        ShowSearch: 'Прикажи претрагу',
        ExtendSearch: 'Прошири претрагу',
        HideSearch: 'Сакриј претрагу',
        AccountingReady: 'Спремно за књижење',
        AssetDocumentNote:
          'Документ није закључен (спреман за књижење). Промене неће имати утицаја на картице докле год документ није закључен',
        AssetDocumentNote2:
          'Уколико број документа остане празан програм ће заузети први слободан број',

        ERP_WMS: 'Магацинско пословање',
        ADMINISTRATION: 'Администрација',
        ASSETS: 'Основна средства',
        CONTRACTS: 'Уговори и оквирни споразуми',
        ExternalInventoryNumber: 'Стари инвентарски број',
        SourceOfSupply: 'Извор набавке',
        Quantity: 'Количина',
        Total: 'Укупно',
        InventoryNumberShort: 'Инв. бр.',
        FixedAssetRecapitulation: 'Рекапитулација основних средстава',
        SmallInventoryRecapitulation: 'Рекапитулација ситног инвентара',
        FixedAssetRecapitulationByLOCATION:
          'Рекапитулација основних средстава по локацији',
        SmallInventoryRecapitulationByLOCATION:
          'Рекапитулација ситног инвентара по локацији',
        FixedAssetRecapitulationByACCOUNT: 'Рекапитулација основних средстава по конту',
        SmallInventoryRecapitulationByACCOUNT: 'Рекапитулација ситног инвентара по конту',
        FixedAssetRecapitulationByACCOUNTABLE:
          'Рекапитулација основних средстава по рачунополагачу',
        SmallInventoryRcapitulationByACCOUNTABLE:
          'Рекапитулација ситног инвентара по рачунополагачу',
        FixedAssetRecapitulationByMUNICIPALITY:
          'Рекапитулација основних средстава по општини',
        SmallInventoryRecapitulationByTYPE_OF_CONSTRUCTION:
          'Рекапитулација ситног инвентара по врсти градње',
        FixedAssetRecapitulationBySUPPLIER:
          'Рекапитулација основних средстава по добављачу',
        WithoutValue: 'Без вредности',
        WithoutAttributeValue: 'Без унетог атрибута',
        Reports: 'Извештаји',
        DeprecationCalculation: 'Обрачун амортизације',
        DeprecationCalculationEdit: 'Измена обрачуна амортизације',
        AddDeprecationCalculation: 'Додавање обрачуна амортизације',
        Note: 'Напомена',
        FromDate: 'Od datuma',
        ToDate: 'Do datuma',
        LastRunDate: 'Датум последњег покретања',
        CreateNewCalculation: 'Направи нови обрачун',
        Deprecation: 'Амортизација',
        CalculationDate: 'Датум обрачуна',
        CalculationPeriod: 'Обрачунски период',
        Successfully: 'Успешно',
        AssetsInCalculation: 'Средства у обрачуну',
        AddAssetInCalculation: 'Додај средство у обрачун',
        AddAssetsInBulkInCalculation: 'Групно додавање средстава у обрачун',
        ProcessingInProgress: 'Процесуирање у току',
        AddActiveAssetsInBulk: 'Додај сва активна средства',
        AmortizationDocuments: 'Налози амортизације',
        AddAssetsInBulk: 'Додај средства групно',
        Actions: 'Акције',
        RunCalculation: 'Покрени обрачун',
        LockCalculation: 'Закључај обрачун',
        AssetTypeFIXED_ASSET: 'Основна средства',
        AssetTypeSMALL_INVENTORY: 'Ситан инвентар',

        Print: 'Одштампај',
        PrintList: 'Одштампајте листу',
        PrintProcessedList: 'Одштампајте обрађену листу',
        ConfigureTheReport: 'Конфигуришите извештај',
        ElementOfGrouping: 'Елемент груписања',
        SortBy: 'Сортирај по',
        FilterBy: 'Филтрирај по',
        FilterByLocation: 'Филтрирај по локацији',
        FilterByAccountable: 'Филтрирај по рачунополагачу',
        FilterByAccount: 'Филтрирај по конту',
        PageBreak: 'Прелом странице',
        WithoutGrouping: 'Без груписања',
        GroupByLocation: 'Групиши по локацији',
        GroupByAccountable: 'Групиши по рачунополагачу',
        GroupByAccount: 'Групиши по конту',
        GroupBySupplier: 'Групиши по добављачу',
        ByAccount: 'Конту',
        ByAssetName: 'Називу основног средства',
        ByInventoryNumber: 'Инвентарском броју',
        WithoutPageBreak: 'Без прелома странице',
        ByGroupItem: 'По елементу груписања',
        UnitOfMeasureShort: 'ЈМ.',
        CountShort: 'Кол.',
        ResponsiblePerson: 'Одговорно лице',
        CommissionMembers: 'Чланови комисије',
        CancelSearch: 'Поништи претрагу',
        SearchByInventoryNumber: 'Претрага по инв. бр.',
        SearchByExternalInventoryNumber: 'Претрага по старом инв. бр.',
        SearchByName: 'Претрага по називу',
        PurchaseDate: 'Датум набавке',
        Rate: 'Стопа',
        FixedAssetName: 'Назив средства',
        CalculationAssetsSearch: 'Претрага средстава у обрачуну',
        DeprecationCalculationRecapitulationByLOCATION:
          'Рекапитулација обрачуна по локацији',
        DeprecationCalculationRecapitulationByACCOUNTABLE:
          'Рекапитулација обрачуна по рачунополагачу',
        DeprecationCalculationRecapitulationByACCOUNT: 'Рекапитулација обрачуна по конту',
        DeprecationCalculationRecapitulationByNO_GROUPING: 'Рекапитулација обрачуна',
        Open: 'Отвори',
        SynteticRecapitulation: 'Синтетичка рекапитулација',

        SupplierDocumentNumber: 'Број документа добављача',
        SupplierDocumentDate: 'Датум документа добављача',
        PaymentDueDate: 'Валута плаћања',
        ReceivedInvoiceBookNumber: 'КПР број',
        Currency: 'Валута',
        ExchangeRate: 'Курс',
        CurrencyExchangeRate: 'Курс за валуту',
        PrintBarcode: 'Одштампај баркод',
        ClientAppPort: 'Порт клијентске апликације',
        Status: 'Статус',
        AssetStatus1: 'Активан',
        AssetStatus0: 'Неактиван',

        CurrentValueZero: 'Садашња вредност једнака 0',
        CurrentValueGraterThanZero: 'Садашња вредност већа од 0',

        SURPLUSBaseDocument: 'Основ за унос вишкова',
        DEFICITBaseDocument: 'Основ за унос мањка',
        VALUE_REDUCTIONBaseDocument: 'Основ за смањење',
        VALUE_INCREASEBaseDocument: 'Основ за повећање',
        EXPENSEBaseDocument: 'Основ за расход',
        SALEBaseDocument: 'Основ за отуђење',
        RECEIPTBaseDocument: 'фактуре',
        Steal: 'Крађа',
        DocumentDate: 'Датум документа',
        JBKJS: 'ЈБКЈС',
        LegalEntityFormENTREPRENEUR: 'Предузетник',
        LegalEntityFormLLC: 'Друштво са ограниченом одговорношћу',
        LegalEntityFormJOINT_STOCK: 'Акционарско друштво',
        LegalEntityFormPARTNERSHIP: 'Ортачко друштво',
        LegalEntityFormAGRICULTURAL_HOLDING: 'Пољопривредно газдинство',
        LegalEntityFormPUBLIC_ENTERPRISE: 'Јавно предузеће',
        LegalEntityFormINSTITUTION: 'Установа',
        BarcodesPrint: 'Одштампај баркодове',
        ItemsWithValue: 'Ставке које учествују у обрачуну',
        BuildingGeneralData: 'Општи подаци о објекту',
        Municipality: 'Општина',
        MUNICIPALITY: 'Општина',
        PropertyTax: 'Порез на имовину',
        TypeOfConstruction: 'Тип градње',
        TYPE_OF_CONSTRUCTION: 'Тип градње',
        CadastralParcelNumber: 'Број катастарске парцеле',
        FixedAssetTehData: 'Технички подаци основног средства',
        Manufacturer: 'Произвођач',
        FactoryCode: 'Фабричка ознака',
        MadeIn: 'Порекло',
        RegisterNumber: 'Регистарски број',
        EngineNumber: 'Број мотора',
        Vin: 'Број шасије',
        ProductionYear: 'Година производње',
        GlassSurface: 'Површина стакла',
        ShowBookQuantity: 'Прикажи стање по књигама',
        Synthetic: 'Синтетика',
        GroupAssetsMove: 'Групни пренос средстава',
        MoveAllAssetsFromOneAccountableToOther:
          'Премештање свих средстава са једног рачунополагача на другог',
        OldAccountable: 'Стари рачунополагач',
        NewAccountable: 'Нови рачунополагач',
        AddItemsInBulkInDocument: 'Групно додавање ставки документа',
        AddItemsInBulk: 'Додај ставке групно',
        MachineType: 'Врста машине',
        VehicleRegistrationType: 'Тип регистровања',
        VehicleRegistrationTypeNO_REGISTRATION: 'Не региструје се',
        VehicleRegistrationTypeTEMPORARY_REGISTRATION: 'Региструје се',
        VehicleRegistrationTypePERMANENT_REGISTRATION: 'Трајна регистрација',
        GlassType: 'Врста стакла',
        TaxDeprecationCalculation: 'Пореска амортизација',
        FilterByType: 'Филтрирај по типу',
        FilterByTypeOfConstruction: 'Филтрирај по типу градње',
        FilterByGlassSurfaceGreaterThan: 'Површина стакла већа од',
        FilterByPropertyTax: 'Филтрирај по порезу на имовину',
        FilterByVehicleRegistrationType: 'Филтрирај по типу регистровања',
        FilterByMachineType: 'Филтрирај по врсти машине',
        FilterBySourceOfSupply: 'Филтрирај по извору набавке',
        DeleteDocument: 'Обриши документ',

        GlassTypes: 'Врсте стакала',
        AddGlassType: 'Додавање врсте стакла',
        EditGlassType: 'Измена података врсте стакла',

        InitialValueByBooks: 'Почетно стање по књигама',
        InitialValueByList: 'Стање по попису',
        LastValueByList: 'Стање после извршеног пописа',
        BookQuantityQ: 'Стање по књигама - количина',
        PurchaseValueByList: 'Набавна по попису',
        DeprecatedValueByList: 'Отписна по попису',
        CurrentValueByList: 'Садашња вредност по попису',
        RealQuantityQ: 'Стање по попису - количина',
        Surplus: 'Вишак',
        Deficit: 'Мањак',
        ExpenseSale: 'Расход / Отуђење',
        InitialQuantity: 'Почетна количина',
        InitialPurchaseValue: 'Почетна набавна вредност',
        InitialDeprecatedValue: 'Почетна отписна вредност',
        InitialCurrentValue: 'Почетна садашња вредност',
        UploadPhoto: 'Додајте фотографију',
        CalculationTotal: 'Укупно за обрачун',
        TotalDocumentValue: 'Укупно за цео документ',
        AccountNumberOfDigits: 'Број цифара конта',
        SpentValue: 'Расходована вредност',
        SpentQuantity: 'Расходована количина',
        FilterByYear: 'Претрага по години',
        Year: 'Година',

        Contract: 'Уговор',
        Contracts: 'Уговори',
        ContractSearch: 'Претрага уговора',
        ContractNumber: 'Број уговора',
        Contractor: 'Партнер',
        ConclusionContractDate: 'Датум закључења уговора',
        AddContract: 'Додавање уговора',
        ConclusionDateFrom: 'Датум закључења од',
        ConclusionDateTo: 'Датум закључења до',
        ExpirationDate: 'Датум истека',
        ContractExpirationDate: 'Рок важења уговора',
        ExpirationDateFrom: 'Датум истека од',
        ExpirationDateTo: 'Датум истека до',
        ContractType: 'Тип уговора',
        ExpiringContractsIn30: 'Уговори који истичу за мање од 30 дана',
        Income: 'Улазни',
        Outcome: 'Излазни',
        DeliveryTimeInDays: 'Рок испоруке у данима',
        ComplaintDeadlineInDays: 'Рок рекламације у данима',
        WarrantyPeriodInMonths: 'Гарантни рок у месецима',
        Items: 'Ставке',
        ContractItemSearch: 'Претрага ставки',
        ValueWithoutVat: 'Вредност без ПДБ-а',
        ValueWithVat: 'Вредност са ПДБ-ом',
        AddContractItem: 'Додавање ставке',
        EditContractItem: 'Измена ставке',
        DateFrom: 'Од датума',
        DateTo: 'До датума',
        ContractSubject: 'Предмет уговора',
        CurrencyCodebook: 'Шифрарник валута',
        ForeignExchange: 'Девизни уговор',
        ForeignCurrencies: 'Девизне валуте',
        DinarCurrency: 'Динарска валута',

        Product: 'Артикал',
        DeleteContractItemConfirmation:
          'Да ли сте сигурни да желите да избришете ставку уговора?',
        DeleteContractItemTitle: 'Брисање ставке уговора',
        Referent: 'Референт',
        AgreedAmountWithVat: 'Уговорени износ пдв',
        AgreedAmountWithoutVat: 'Уговорени износ без пдв',
        WarningAmount: 'Износ упозорења',
        WarningAmountWithVat: 'Износ упозорења са пдв',
        WarningAmountWithoutVat: 'Износ упозорења без пдв',
        WithVat: 'Са пдв',
        WithoutVat: 'Без пдв',
        WarningDate: 'Датум упозорења',
        BestPayBeforeDays: 'Број дана валуте',
        DeactivateContract: 'Деактивирај уговор',
        DeactivationDate: 'Датум деактивације',
        DeactivationNote: 'Образложење деактивације',
        ContractDeactivated: 'Уговор је деактивиран.',
        Explanation: 'Образложење',
        ParticipateInContractRealization: 'Учествује у реализацији уговора',
        Realization: 'Реализација',
        Utilized: 'Реализовано',
        Agreed: 'Уговорено',
        RemainingAmount: 'Преостали износ',
        RealizationPercentage: 'Проценат реализације',
        ContractDurationInDays: 'Број дана важења',
        DiscountPercentage: 'Рабат',
        UnitPriceWithoutVat: 'Цена без ПДВ-а',
        UnitPriceWithVat: 'Цена са ПДВ-ом',
        SerialNumber: 'Серијски број',
        CodeAndName: 'Шифра и назив',
        ViewProduct: 'Преглед артикла',

        Currencies: 'Валуте',
        CurrencyCode: 'Ознака валуте',
        CurrencyNumericCode: 'Шифра валуте',
        AddCurrency: 'Додај валуту',
        EditCurrency: 'Измени валуте',
        ViewCurrency: 'Преглед валуте',

        Manufacturers: 'Произвођачи',
        AddManufacturer: 'Додај произвођача',
        EditManufacturer: 'Измени произвођача',
        ViewManufacturer: 'Преглед произвођача',
        IsActive: 'Активан',

        BillsOfExchange: 'Менице',
        BillOfExchangeNumber: 'Број менице',
        ItemNumber: 'Број средства',
        BillOfExchangeItem: 'Средство менице',
        Received: 'Примљена',
        Given: 'Дата',
        ItemDate: 'Датум средства',
        ItemAmount: 'Износ средства',
        ItemType: 'Врста средства',
        ViewBillOfExchange: 'Преглед менице',
        EditBillOfExchange: 'Измени меницу',
        AddBillOfExchange: 'Додај меницу',
        AuthorizationNumber: 'Број овлашћења',
        AuthorizationDate: 'Датум овлашћења',
        RegistrationDate: 'Датум Регистрације',
        ValidUntil: 'Рок важности',
        Amount: 'Износ',
        ReturnDate: 'Датум враћања',
        ReturnDateWarning: 'Упозорење за датум враћања',
        DeleteBillOfExchangeTitle: 'Брисање менице',
        DeleteBillOfExchangeConfirmation:
          'Да ли сте сигурни да желите да обришете меницу',

        BankGuarantee: 'Банкарске гаранције',
        BankGuaranteeNumber: 'Број банкарске гаранције',
        BankGuaranteeItem: 'Средство гаранције',
        ViewBankGuarantee: 'Преглед гаранције',
        EditBankGuarantee: 'Измени гаранцију',
        AddBankGuarantee: 'Додај гаранцију',
        DeleteGuaranteeTitle: 'Брисање гаранције',
        DeleteGuaranteeConfirmation: 'Да ли сте сигурни да желите да обришете гаранцију',
        ContractWithBankNumber: 'Број уговора са банком',

        DeleteContactPersonTitle: 'Брисање контакт особе',
        DeleteContactPersonConfirmation:
          'Да ли сте сигурни да желите да обришете контакт особу',
        ConclusionDate: 'Датум закључења',
        Returned: 'Враћена',
        CopyContract: 'Копирај уговор',
        ChooseContractToToCopyItems: 'Изабрати уговор са ког желите да копирате ставке',
        CopyDocumentItems: 'Копирај ставке',
        ContainsProduct: 'Садржи артикал',

        Supplies: 'Залихе',
        Area: 'Зона',
        StorageUnitAddress: 'Складишна јединица',
        StockList: 'Лагер листа',
        WarehouseSelection: 'Избор магацина',
        State: 'Стање',
        CORRECT: 'Исправан',
        UNTESTED: 'Нетестиран',
        FAULTY: 'Неисправан',
        PurchasePrice: 'Набавна цена',
        SalePrice: 'Продајна цена',
        SaleValue: 'Продајна вредност',
        InStock: 'На стању',
        DocumentPrint: 'Штампа документа',
        Place: 'Место',
        TrafficDate: 'Датум промета',
        WarehouseName: 'Назив магацина',
        ShowPrices: 'Прикажи цене',
        Number: 'Број',
        ReceivedBy: 'Робу примио',
        GivenBy: 'Робу издао',
        Confirmed: 'Потбрђен',
        InPreparation: 'У припреми',
        FromWarehouse: 'Из магацина',
        ToWarehouse: 'У магацин',
        // Errors
        NoInternetError: 'Проверите интернет конекцију',
        DOCUMENT_EXISTS: 'Документ са тим бројем већ постоји',
        PRODUCT_CODE_ALREADY_EXISTS: 'Шифра артикла већ постоји!',
        LEGAL_ENTITY_DOES_NOT_HAVE_AT_LEAST_REG_NUM_TAX_ID_PERSONAL_IDENT_NUM:
          'Поља: Матични број, ПИБ или ЈМБГ морају бити попуњена',

        EnginePower: 'Снага мотора',
        GarageNumber: 'Гаражни број',
        Leasing: 'Лизинг',
        LeasingNO_LEASING: 'Без лизинга',
        LeasingFINANCIALLY: 'Финансијски',
        LeasingOPERATIONAL: 'Оперативни',

        FA_DOCUMENT_NUMBER_EXISTS: 'Унети инвентарски број већ постоји',

        InvoiceNumber: 'Број фактуре',
        InvoiceDate: 'Датум фактуре',

        Price: 'Cena',

        AuditLogs: 'Ревизија логова',
        EntityClass: 'Класа ентитета',
        User: 'Корисник',
        Time: 'Време',
        Action: 'Акција',
        Entity: 'Ентитет',
        EntityIdentifier: 'Идентификатор ентитета',
        OnlyMyLogs: 'Само моја логовања',
        Identifier: 'Идентификатор',
        EntityIdentifierRepresentation: 'Репрезентација идентификатора ресурса',
        IpAddress: 'ИП адреса',
        AuditLogItems: 'Ставке ревизије',
        AttributeName: 'Назив атрибута',
        OldValue: 'Стара вредност',
        NewValue: 'Нова вредност',
        ActionEDIT: 'Измена',
        ActionCREATE: 'Креирање',
        ActionLOG_IN: 'Логовање',
        ActionDELETE: 'Брисање',
        ActionPREVIEW: 'Преглед',
        ActionLOGIN_FAILED: 'Неуспешно логовање',
        EntityClassUser: 'Корисник',
        EntityClassWHDocument: 'Документ',
        EntityClassWHDocumentItem: 'Ставка документа',
        EntityClassProduct: 'Артикал',
      },
    },
    /*en: {
            translations: {
                Yes: "Yes",
                No: "No",
                Save: "Save",
                Accept: "Accept",
                Confirm: "Confirm",
                WithoutPrivileges: "The user does not have the right to access this page!",
                NumberOfRows: "Number of rows",
                OrderNumber: "Num",
                Codebooks: "Codebooks",
                ItemClassification: "Article classification",
                NavigationMenu: "Navigation menu",

                Users: "Users",
                UserName: "Username",
                Name: "Name",
                FirstName: "First name",
                LastName: "Last name",
                Email: "Email",
                Role: "Role",
                Password: "Password",
                RepeatPassword: "Repeat password",
                AddUser: "Add user",
                EditUser: "Edit user",
                CancelEditMode: "Cancel edit mode",
                CancelAddMode: "Cancel add mode",
                HomePage: "Home page",

                Organizations: "Organizations",
                RegistrationNumber: "Registration number",
                TaxId: "Tax id",
                AddOrganization: "Add organization",
                EditOrganization: "Edit organization",

                UnitOfMeasure: "Unit of measure",
                UnitsOfMeasure: "Units of measure",
                AddUnitOfMeasure: "Add unit of measure",
                EditUnitOfMeasure: "Edit unit of measure",

                ClassificationType: "Classification type",
                ClassificationTypes: "Classification types",
                Description: "Description",
                Primary: "Primary",
                AddClassificationType: "Add classification type",
                EditClassificationType: "Edit classification type",

                Classification: "Classification",
                Mandatory: "Mandatory",
                AddClassification: "Add classification",
                AddToClassification: "Add to classification",
                EditClassification: "Edit classification",
                UserDefinedCode: "User defined code",
                AddAttribute: "Add attribute",

                MainProducts: "Main products",
                MainProduct: "Main product",
                MinStockLevel: "Min stock level",
                ClassificationName: "Classification name",
                FilterByName: "Filter by name",
                AddMainProduct: "Add main product",
                EditMainProduct: "Edit main product",
                AddProductClassification: "Add classification",

                Products: "Products",
                ProductName: "Product name",
                Code: "Code",
                AddProduct: "Add product",
                EditProduct: "Edit product",
            }
        },*/
  },
  fallbackLng: 'sr_RS_Cyrl',
  debug: true,

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,

  interpolation: {
    formatSeparator: ',',
  },

  react: {
    useSuspense: true,
  },
});

i18n.services.pluralResolver.addRule('sr_RS_Latn', {
  numbers: [0, 1, 'few', 'plural'],
  plurals: function plurals(n: number) {
    if (n > 1 && n < 5) return 'few';
    else if (n >= 5) return 'plural';
    else return n;
  },
});

export default i18n;
