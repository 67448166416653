import { FC, Fragment, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  fetchUserInfo,
  fetchLoggedUserOrganizations,
  fetchLoggedUserModules,
} from '../actions/ActiveUserActions';
import i18n from '../i18n';
import {
  getActiveOrganizationUuid,
  getToken,
  removeToken,
} from '../utils/LocalStorageHelper';
import { IOrganization } from '../entity/IOrganization';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { IUser } from '../entity/IUser';
import { IPrivileges } from '../entity/Privileges';
import { IFixedAssetDocumentType } from '../entity/asset/IFixedAssetDocumentType';
import { parseJwt } from '../utils/TokenUtilsHelper';
import { getDocumentTypes } from '../repository/assets/DocumentsRepository';
import MainHeader from '../components/Header/MainHeader';
import Sidebar from '../components/Sidebar/Sidebar';
import { getWmsDocumentTypes } from '../repository/wms/WmsDocumentsRepository';
import { IWmsDocumentType } from '../entity/wms/IWmsDocument';
import { SubSystem } from '../entity/SubSystem';

interface Props extends RouteComponentProps {
  userInfo: IUser;
  privileges: IPrivileges;
  loggedUserOrganizations: IOrganization[];
  loggedUserModules: string[];
  sidebarFixed: boolean;
  sidebarToggleDesktop: boolean;
  appLoader: boolean;
  fetchUserInfo: Function;
  fetchLoggedUserOrganizations: Function;
  fetchLoggedUserModules: Function;
  children: any;
}

const MainPage: FC<Props> = ({
  userInfo,
  privileges,
  loggedUserOrganizations,
  loggedUserModules,
  sidebarFixed,
  sidebarToggleDesktop,
  appLoader,
  fetchUserInfo,
  fetchLoggedUserOrganizations,
  fetchLoggedUserModules,
  children,
}) => {
  const [documentTypes, setDocumentTypes] = useState<IFixedAssetDocumentType[]>([]);
  const [wmsDocumentTypes, setWmsDocumentTypes] = useState<IWmsDocumentType[]>([]);

  const h = useHistory();
  const activeModule = h.location.pathname.split('/')[1];

  const handleGetData = useCallback(
    (userUuid) => {
      if (!userInfo || userUuid !== userInfo.uuid) {
        fetchUserInfo();
      }
      fetchLoggedUserOrganizations();
      fetchLoggedUserModules();
    },
    [userInfo, fetchUserInfo, fetchLoggedUserOrganizations, fetchLoggedUserModules]
  );

  useEffect(() => {
    if (activeModule === SubSystem.ASSETS) {
      getDocumentTypes(getActiveOrganizationUuid())
        .then((response) => {
          setDocumentTypes(response.documentTypes);
        })
        .catch(() => {});
    } else if (activeModule === SubSystem.ERP_WMS) {
      getWmsDocumentTypes(undefined, false)
        .then((data) => {
          setWmsDocumentTypes(data);
        })
        .catch(() => {});
    } else if (activeModule === SubSystem.ERP_SMALL_INVENTORY) {
      getWmsDocumentTypes(undefined, true)
        .then((data) => {
          setWmsDocumentTypes(data);
        })
        .catch(() => {});
    }
  }, [activeModule]);

  useEffect(() => {
    const activeUserToken = getToken();
    if (parseJwt(activeUserToken) === null) {
      removeToken();
      h.push(`/login?nextUrl=${window.location.pathname}`);
    } else {
      const userUuid = parseJwt(activeUserToken).userUuid;
      handleGetData(userUuid);
    }
  }, [handleGetData, h]);

  useEffect(() => {
    if (loggedUserOrganizations?.length) {
      const activeOrganization = loggedUserOrganizations.find(
        (organization) => organization.uuid === getActiveOrganizationUuid()
      );
      i18n.changeLanguage(activeOrganization!.locale!);
    }
  }, [loggedUserOrganizations]);

  const readyForPrint = h.location.pathname?.endsWith('-print.html');
  const reportPage = h.location.pathname?.endsWith('-report.html');
  return (
    <Fragment>
      <div className={appLoader ? 'overlay-loading app-wrapper' : 'app-wrapper'}>
        {userInfo && (
          <MainHeader
            activeUser={userInfo}
            loggedUserModules={loggedUserModules}
            history={h}
            readyForPrint={readyForPrint}
            reportPage={reportPage}
          />
        )}
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed,
          })}>
          {!!userInfo && !!documentTypes && (
            <Sidebar
              userInfo={userInfo}
              privileges={privileges}
              documentTypes={documentTypes}
              wmsDocumentTypes={wmsDocumentTypes}
              // loggedUserOrganizations={loggedUserOrganizations}
              readyForPrint={readyForPrint}
              reportPage={reportPage}
            />
          )}
          <div
            className={clsx('app-content', {
              'app-content-sidebar-fixed': sidebarFixed && !sidebarToggleDesktop,
              'app-content-ready-for-printing': readyForPrint,
              'app-content-report-page': reportPage,
            })}>
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </div>
      {appLoader && <CircularProgress className="component-loader" />}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.activeUserStore.userInfo,
  privileges: state.activeUserStore.privileges,
  loggedUserOrganizations: state.activeUserStore.loggedUserOrganizations,
  loggedUserModules: state.activeUserStore.loggedUserModules,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  sidebarToggleDesktop: state.ThemeOptions.sidebarToggleDesktop,
  appLoader: state.appStore.appLoader,
});

const mapDispatchToProps = {
  fetchUserInfo,
  fetchLoggedUserOrganizations,
  fetchLoggedUserModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
